.energy-table {
	width: 100%;
	margin-bottom: 50px;

	& > thead > tr {
		background-color: rgb(219, 233, 246);
		color: #337ab7;

		& > th {
			vertical-align: middle;
			text-align: center;

			&.energy-table-header-color {
				background-color: rgba(66, 139, 202, 0.05);
				border-width: 0;
			}

			&.energy-table-header-no-color {
				background-color: transparent;
				border-width: 0;
			}

			&.energy-table-header-row {
				border-width: 0;
			}

			& i.fa-bar-chart-o {
				cursor: url('/resources/css/img/charcolumn.png'), auto;
			}
		}
	}

	& > tbody > tr {
		& > td {
			white-space: nowrap;
			text-align: left;

			&.energy-table-row {
				background-color: rgba(66, 139, 202, 0.05);
			}

			& .energy-table-cell {
				position: relative;
				height: 100%;
				margin: -8px;
				padding: 8px;
				cursor: url('/resources/css/img/charrow.png'), auto;

				& .cell-bar {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					transition: width 0.5s ease-out 0s;
				}

				& .cell-value {
					position: relative;
					z-index: 10;
				}
			}
		}
	}

	& > tfoot > tr > td {
		white-space: nowrap;
	}
}
