@import url('/jslib/bootstrap/less/variables');

@keyframes blink {
    from {
        color: inherit;
    }
    to {
        color: white;
    }
}

table.report-table {
    tr.error {
        color: inherit;
        background-color: @alert-danger-bg;
    }
    tr.updated {
        animation: blink;
        animation-duration: 0.3s;
        animation-direction: alternate;
        animation-iteration-count: 2;
    }
    td.bold{
        font-weight: bold;
        background-color: rgba(66, 139, 202, 0.05);
    }
    th{
        background-color: rgba(66, 139, 202, 0.1);
    }
    th a {
        cursor: pointer;
    }
}
