.logoMini {
    height: 35px;
    content: url("/resources/css/img/ff.png");
}

.logoMini2 {
    height: 35px;
    content: url("/resources/css/img/ff2.png");
}

.logoMini_eti {
    height: 35px;
    content: url("/resources/css/img/eti.png");
}

.logoMini2_eti {
    height: 35px;
    content: url("/resources/css/img/eti2.png");
}

.logoMini_efen {
    height: 35px;
    content: url("/resources/css/img/efen.png");
}

.logoMini2_efen {
    height: 35px;
    content: url("/resources/css/img/efen.png");
}

.logoMini_bsh {
    height: 35px;
    content: url("/resources/css/img/bsh.png");
}

.logoMini2_bsh {
    height: 35px;
    content: url("/resources/css/img/bsh2.png");
}

.logoMini_finn {
    height: 35px;
    content: url("/resources/css/img/finn.png");
}

.logoMini2_finn {
    height: 35px;
    content: url("/resources/css/img/finn2.png");
}

.table-markers.table > tbody > tr > td {
    vertical-align: middle;
}

.table-markers.table > tbody > tr.hover > td {
    background-color: #c4e3f3;
}

.mbsat-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.mbsat-container .mbsat-map {
    display: flex;
    flex: 1 1 auto;
    height: 100%;
    flex-direction: column;
    overflow-y: auto;
}
.mbsat-container .bar-graph-container {
    min-height: 300px;
}
.mbsat-container .line-chart {
    min-height: 300px;
}
.mbsat-container .table-gauge {
    min-height: 300px;
}
.back-to-map-btn {
    padding: 5px;
    text-align: right;
}

.marker-popup p {
    margin: 0;
}

.mbsat-dashboard{
    height: 550px;
    min-height: 550px;
}

.save-panel {
    padding: 0 3px;
}
.save-panel h4 {
    margin-bottom: 0;
}

.mbsat-map .device-calendar-container {
    height: 100%;
}

.mbsat-map .device-calendar {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding-top: 3px;
    margin: 0;
}

.mbsat-menu {
    margin-bottom: 10px;
}

.mbsat-menu > div {
    display: inline;
}

.mbsat-menu .fa {
    color: #337ab7;
}

.mbsat-menu > div > a, .mbsat-menu > a {
    margin-left: 10px;
}

.device-label {
    font-size: 100%;
    margin-right: 3px;
    padding: .3em .6em .3em;
    margin-bottom: 3px;
}

.mbsat-table .btn-group {
    padding-left: 3px;
}

.flex-row, .edit-row {
    display: flex;
    align-items: center;
}
#eventSetModal .flex-row{
    align-items: baseline;
}

.edit-row > * {
    margin-right: 10px;
}
.fc-event {
    margin-bottom: 0 !important;
}

.fc-event.editable {
    cursor: pointer;
}

.fc-today {
    background: #FFF !important;
}

.fc-dawn-dusk, .fc-event-indicator, .fc-dawn-dusk-shift {
    cursor: default;
}

.fc-event-indicator {
    width: 10px !important;
    border-radius: 0 !important;
    opacity: 0.7;
}
.fc-event-indicator .fc-time {
    display: none;
}

.fc-dawn-dusk {
    width: 103% !important;
    left: -1% !important;
    z-index: 10 !important;
    opacity: 0.7;
}

.fc-dawn-dusk-shift {
    border: 1px dashed !important;
}

.lighting-settings .dual-list .list-group {
    margin-top: 8px;
}
.lighting-settings .dual-list {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding-top: 20px;
    border-top: 1px solid #ddd;
}

.mbsat-table .alert {
    margin-left: 3px;
}
.lighting-settings h4 {
    margin-top: 7px;
    margin-bottom: 7px;
    margin-right: 0;
    font-weight: bold;
}

.lighting-settings .flex-row {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
}

.lighting-settings .dual-list .pre-scrollable {
    overflow-y: auto;
    max-height: 300px;
    border-top: 2px solid #ddd;
}
.lighting-settings .dual-list.list-right {
    background-color: rgba(66, 139, 202, 0.05);
}
.lighting-settings .row.markers-search {
    margin: 0;
    display: flex;
}
.lighting-settings .dual-list.list-left .markers-checkbox {
    align-self: center;
}

.lighting-settings .dual-list .panel {
    flex: 1 1 auto;
}

.lighting-settings .flex-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.lighting-settings .flex-row .well {
    height: 100%;
}

.lighting-settings .btn-container {
    text-align: center;
    align-self: center;
    margin: 5px 0;
}

.lighting-settings .selector i {
    width: 22px;
}

.lighting-settings .panel-body {
    padding: 5px 0 !important;
}

.lighting-settings .btn-row {
    padding: 5px;
}

.lighting-settings .list-left tr, .lighting-settings .list-right tr {
    cursor: pointer;
}

.lighting-settings .list-left li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.lighting-settings .table-responsive {
    flex: 1 1 auto;
}

.lighting-settings table {
    margin-bottom: 0;
}

.lighting-settings .list-group {
    margin-bottom: 0;
}

.lighting-settings .list-group-item.active {
    color: black;
    background-color: whitesmoke;
}
.lighting-settings .lightingSetting-label {
    display: flex;
    justify-content: space-between;
}
.cost-report {
    margin-top: 10px;
}
.cost-report .panel-group {
    margin-bottom: 2px;
}
.cost-report hr {
    border-top: 2px solid #eeeeee;
}
.cost-report .header {
    color: #428bca;
    font-weight: bold;
}
.cost-report .panel-heading {
    background-color: rgb(219, 233, 246); --darkreader-inline-bgcolor:#1d2f3e;
    cursor: pointer;
}
.custom-time-picker .with-addon {
    display: flex;
    vertical-align: middle;
    position: relative;
    border-collapse: separate;
}

.custom-time-picker .form-inline .with-addon > .form-control {
    width: 100%;
}

.custom-time-picker .form-inline .with-addon .input-group-addon {
    width: auto;
}

.custom-time-picker .form-inline .with-addon .input-group {
    display: flex;
}

.custom-time-picker .form-inline .with-addon .input-group input {
    border-radius: 0;
}

.custom-time-picker .bootstrap-datetimepicker-widget {
    width: auto;
}

.custom-time-picker .flex-icon {
    display: flex;
    align-items: center;
}

.width-fix {
    margin-bottom: 10px;
}

.width-fix .bootstrap-datetimepicker-widget {
    width: 100%;
}

.device-calendar .dawn {
    background-color: #e68a0f;
    text-align: center !important;
}

.device-calendar .dusk {
    background-color: #224f77;
    text-align: center !important;
    color: white;
}
.device-calendar .flex-row-device {
    flex-wrap: wrap;
    padding: 3px;
    justify-content: space-between;
}
.flex-wrap {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
}

.fontawesome-select {
    font-family: 'FontAwesome', 'Helvetica', sans-serif;
}

.marker-popup > span {
    word-break: break-word;
}

.marker-popup > p {
    margin: 5px 0 !important;
}

.marker-popup a:hover, a:focus {
    text-decoration: none;
}

.marker-popup .checkIcon {
    display: inline-block;
    width: 19px;
}
.load-table {
    margin-top: 10px;
}
.load-table table {
    width: auto;
}
.load-table table thead {
    background-color: rgb(219, 233, 246);
}
.load-table table tbody {
    background-color: rgba(66, 139, 202, 0.05);
}

.mbsat2-pre-scrollable {
    overflow-y: scroll;
    height: 200px;
    border-top: 2px solid #ddd;
}
