@tick-major-color: #c0c0c0;
@tick-minor-color: @tick-major-color;
@grid-major-color: lighten(@tick-major-color, 10%);
@grid-minor-color: lighten(@tick-major-color, 17%);

.username-select (@value) {
    -webkit-touch-callout: @value;
    -webkit-username-select: @value;
    -khtml-username-select: @value;
    -moz-username-select: @value;
    -ms-username-select: @value;
    username-select: @value;
}


svg {
  * {
    .username-select(none);
  }
  text {
    font-size: 11px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    fill: black;
    color: black;
  }
}

g.draggable {
    cursor: url(/resources/css/img/openhand.cur) 8 8, move;
}

g.draggable.dragged {
    cursor: url(/resources/css/img/closedhand.cur) 8 8, move;
}

svg.line-chart{
  background-color: #ffffff;
}

.line-chart {
    position: relative;
    background-color: #ffffff;
    div.chart-tooltip {
        pointer-events: none;
        padding: 10px;
        margin: 0;
        border: 1px solid gold;
        border-radius: 5px;
        background-color: mix(gold, white, 50%);
        box-shadow: 3px 3px 10px gray;
        font-size: 12px;

        p {
            margin: 0 0 5px 0;
        }

        p:last-child {
            margin: 0;
        }

        span.fa {
            margin: 0 0 0 15px;
        }
        span.value, span.time {
            font-weight: bold;
        }
    }

  g.chart-data g.holes rect {
    fill: red;
    fill-opacity: 0.1;
    stroke: none;
    stroke-width: 0;
  }
}

g.chart-area {
    & * {
        pointer-events: none;
    }

    rect.chart-area-fill {
        fill: none;
        stroke: @tick-major-color;
        pointer-events: all;
    }

    line.tooltip-line-x {
        stroke: black;
        stroke-width: 1;
        stroke-dasharray: 5 5;
        shape-rendering: crispEdges;
    }

    text.icon {
        font-size: 32px;
        fill: black;
        opacity: 0.3;
        cursor: pointer;
        pointer-events: auto;
        &:hover {
            opacity: 1;
            transition: opacity 1s;
        }
    }

    g.loader {
        rect {
            stroke: none;
            fill: black;
            opacity: 0.3;
        }

        text {
            fill: white;
            text-anchor: middle;
            font-size: 14px;
        }
    }
}

.axis path,
.axis line,
line.tick {
    fill: none;
    stroke: @tick-major-color;
    shape-rendering: crispEdges;
}

.axis {
    text {
        stroke: none;
        fill: black;
    }

    .extreme {
        rect {
            fill: white;
            stroke: none;
        }

        text {
            font-weight: bold;
        }

        &.min text {
            text-anchor: start;
        }

        &.max text {
            text-anchor: end;
        }
    }
}

.tick {
    fill: none;
    shape-rendering: crispEdges;

    &.tick-major line {
        stroke: @tick-major-color;
    }
    &.tick-minor line {
        stroke: @tick-minor-color;
    }
}

.grid {
    fill: none;
    shape-rendering: crispEdges;

    &.grid-major line {
        stroke: @grid-major-color;
    }
    &.grid-minor line {
        stroke: @grid-minor-color;
    }
}

text {
    /*    fill: #666;*/
}

.line {
    fill: none;
    /*stroke-width: 1;*/
}

:fullscreen::backdrop {
    background-color: #000; /* dark blue */
}

svg.shift-bars-chart rect.bar-1 {
    fill: steelblue;
    stroke: none;
}

svg.shift-bars-chart rect.bar-2 {
    fill: green;
    stroke: none;
}

svg.shift-bars-chart rect.bar-3 {
    fill: violet;
    stroke: none;
}

svg.shift-bars-chart g.bars text {
  text-anchor: middle;
  font-size: 14px;
}

g.legend g.legend-item {
    & {
        cursor: pointer;
    }

    & text.label {
        font-family: "Courier New";
        font-size: 12px;
    }

    &.disabled {
        opacity: 0.5;
    }
}

g.measure.active > text {
  fill:black;
}

g.measure > text {
  fill: gray;
}

.file-drop {
    height: 150px;
    border: dashed 2px #d3d3d3;
    border-radius: 5px;
    padding: 5px;
    margin: 5px 0;
    overflow: hidden;
    position: relative;
    text-align: center;
  cursor: pointer;

    &.active {
        border-color: black;
    }

    & img {
        height: 100px;
        max-width: 100%;
        margin-bottom: 5px;
    }

    & .file-drop-placeholder {
        line-height: 140px;

    }
}

.heatmap {
    g.heatmap-data {
        rect {
            stroke: none;
            stroke-width: 0;
            opacity: 0.3;
        }
    }

    g.heatmap-label {
        .heatmap-label-shape {
            fill: white;
            stroke: black;
            stroke-width: 1;
        }

        text.heatmap-label-index {
            font-size: 14px;
        }
    }
}

.shift-bar-graph {
  background-color: white;
}

.shift-bars-chart {
  g.bar {
    fill: steelblue;
  }
}
