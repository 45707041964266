.fullscreen {
	padding-left: 5px;
	padding-right: 5px;
	background-color: white;
	height:100%;
}

:fullscreen::backdrop {
    background-color: white;
}

:full-screen {
	//width: 100%;
	padding-left: 5px;
	padding-right: 5px;
	background-color: white;
}

:-webkit-full-screen {
	//width: 100%;
	padding-left: 5px;
	padding-right: 5px;
	background-color: white;
}

:-moz-full-screen {
	//width: 100%;
	padding-left: 5px;
	padding-right: 5px;
	background-color: white;
}
