.media-report-edit {
	.ui-select-search {
		width: 100% !important;
	}

	.ui-select-toggle {
		width: 100% !important;
	}

	.form-control {
		width: 100%;
	}
}
